import {Injectable} from '@angular/core';
import {ActiveToast, IndividualConfig} from "ngx-toastr";

export abstract class SvToastrService {

  constructor() {
  }

  public danger<ConfigPayload = any>(message?: string, title?: string, override?: Partial<IndividualConfig<ConfigPayload>>): ActiveToast<any> {
    return this.error();
  }

  public abstract error<ConfigPayload = any>(message?: string, title?: string, override?: Partial<IndividualConfig<ConfigPayload>>): ActiveToast<any>;

  public abstract info<ConfigPayload = any>(message?: string, title?: string, override?: Partial<IndividualConfig<ConfigPayload>>): ActiveToast<any>;

  public abstract show<ConfigPayload = any>(message?: string, title?: string, override?: Partial<IndividualConfig<ConfigPayload>>, type?: string): ActiveToast<any>;

  public abstract success<ConfigPayload = any>(message?: string, title?: string, override?: Partial<IndividualConfig<ConfigPayload>>): ActiveToast<any>;

  public abstract warning<ConfigPayload = any>(message?: string, title?: string, override?: Partial<IndividualConfig<ConfigPayload>>): ActiveToast<any>;
}
